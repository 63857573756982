import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TermsGeneric } from 'src/helpers';
import { AdminAuthGuard } from './core/admin-auth.guard';
import { AuthGuard } from './core/auth.guard';

@NgModule({
  imports: [
    RouterModule.forRoot([
      {
        path: '',
        title: `${TermsGeneric.NameApp}`,
        loadChildren: () =>
          import('./entry/entry-routes').then((m) => m.EntryRoutes),
      },
      {
        path: 'map',
        title: `${TermsGeneric.NameApp} - Map`,
        loadChildren: () =>
          import('./new-map/new-map.module').then((m) => m.NewMapModule),
        canActivate: [AdminAuthGuard],
      },
      {
        path: `${TermsGeneric.Board.Lower.Plural}`,
        title: `${TermsGeneric.NameApp} - ${TermsGeneric.Board.Title.Plural}`,
        loadChildren: () =>
          import('src/app/board/board-routes').then((m) => m.BoardRoutes),
        canActivate: [AuthGuard],
      },
      {
        path: 'admin',
        title: `${TermsGeneric.NameApp} - Admin`,
        loadChildren: () =>
          import('./admin/admin-routes').then((m) => m.AdminRoutes),
        canActivate: [AdminAuthGuard],
      },
      {
        path: `${TermsGeneric.Station.Single.toLowerCase()}`,
        title: `${TermsGeneric.NameApp} - ${TermsGeneric.Board.Title.Plural}`,
        pathMatch: 'full',
        redirectTo: `/${TermsGeneric.Board.Lower.Plural}`,
      },
      {
        path: `${TermsGeneric.Station.Single.toLowerCase()}`,
        title: `${TermsGeneric.NameApp} - ${TermsGeneric.Station.Single}`,
        loadChildren: () =>
          import('./station/station-routes').then((m) => m.StationRoutes),
        canActivate: [AdminAuthGuard],
      },
      {
        path: `${TermsGeneric.Container.Single.toLowerCase()}`,
        title: `${TermsGeneric.NameApp} - ${TermsGeneric.Container.Single}`,
        loadChildren: () =>
          import('./container/container-routes').then((m) => m.ContainerRoutes),
        canActivate: [AuthGuard],
      },
      {
        path: 'settings',
        title: `${TermsGeneric.NameApp} - Settings`,
        loadChildren: () =>
          import('./settings/settings-routes').then((m) => m.SettingRoutes),
        canActivate: [AuthGuard],
      },
      {
        path: 'search',
        title: `${TermsGeneric.NameApp} - Search`,
        loadChildren: () =>
          import('./search/search-routes').then((m) => m.SearchRoutes),
        canActivate: [AuthGuard],
      },
    ]),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
